<template>
  <app-header/>
  <Suspense>
      <template #default>
  <app-login/>
  </template>
      <template #fallback>
        <app-loading />
      </template>
      </Suspense>
  <app-footer/>
</template>

<script>
import AppFooter from '../../components/layouts/AppFooter.vue'
import AppHeader from '../../components/layouts/AppHeader.vue'
import AppLogin from '../../components/Users/AppLogin.vue'
import AppLoading from '../../components/Spinners/AppLoading.vue'
import { Suspense } from 'vue'
export default {
  components: { AppHeader, AppLogin, AppFooter, AppLoading, Suspense },
  name: 'Login'
}
</script>

<style>

</style>